<template>
  <div class="cp_user_list_wrap">
    <div class="cp_user_thumb_list_box">
      <div class="cp_user_thumb_list">
        <div class="cp_user_thumb_img">
          <div class="cp_user_thumb_badge">
            <span>14:35:53</span>
          </div>
        </div>
        <div class="cp_user_thumb_type">Art</div>
        <div class="cp_user_thumb_title">병원로고 디자인 의뢰</div>
      </div>
      <div class="cp_user_thumb_list">
        <div class="cp_user_thumb_img">
          <div class="cp_user_thumb_badge">
            <span>D-12</span>
          </div>
        </div>
        <div class="cp_user_thumb_type">Art</div>
        <div class="cp_user_thumb_title">병원로고 디자인 의뢰</div>
      </div>
      <div class="cp_user_thumb_list">
        <div class="cp_user_thumb_img">
          <div class="cp_user_thumb_badge">
            <span>D-12</span>
          </div>
        </div>
        <div class="cp_user_thumb_type">Art</div>
        <div class="cp_user_thumb_title">병원로고 디자인 의뢰</div>
      </div>
      <div class="cp_user_thumb_list mr0">
        <div class="cp_user_thumb_img">
          <div class="cp_user_thumb_badge">
            <span>D-12</span>
          </div>
        </div>
        <div class="cp_user_thumb_type">Art</div>
        <div class="cp_user_thumb_title">병원로고 디자인 의뢰</div>
      </div>
      <div class="cp_user_thumb_list">
        <div class="cp_user_thumb_img">
          <div class="cp_user_thumb_badge">
            <span>14:35:53</span>
          </div>
        </div>
        <div class="cp_user_thumb_type">Art</div>
        <div class="cp_user_thumb_title">병원로고 디자인 의뢰</div>
      </div>
      <div class="cp_user_thumb_list">
        <div class="cp_user_thumb_img">
          <div class="cp_user_thumb_badge active">
            <span>발표완료</span>
          </div>
        </div>
        <div class="cp_user_thumb_type">Art</div>
        <div class="cp_user_thumb_title">병원로고 디자인 의뢰</div>
      </div>
      <div class="cp_user_thumb_list">
        <div class="cp_user_thumb_img">
          <div class="cp_user_thumb_badge">
            <span>D-12</span>
          </div>
        </div>
        <div class="cp_user_thumb_type">Art</div>
        <div class="cp_user_thumb_title">병원로고 디자인 의뢰</div>
      </div>
      <div class="cp_user_thumb_list">
        <div class="cp_user_thumb_img">
          <div class="cp_user_thumb_badge">
            <span>14:35:53</span>
          </div>
        </div>
        <div class="cp_user_thumb_type">Art</div>
        <div class="cp_user_thumb_title">병원로고 디자인 의뢰</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompetitionListUserLayout",
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>

<style scoped>

</style>